import { useEffect } from 'react'
import { AuthClient, SignInRequest } from 'src/api/access/Auth';
import { Button } from 'src/components/Button'
import FormInput from 'src/components/Form/FormInput';
import { MetaHead } from 'src/components/MetaHead';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import useEventTracking from 'src/hooks/useEventTracking';
import useForm from 'src/hooks/useForm';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import useUser from 'src/hooks/useUser';
import { setToken } from 'src/store/application/actions';

export default function SignIn() {
  const form = useForm<SignInRequest>();
  const user = useUser();

  const navigate = useLocalizedNavigate();
  const applicationDispatch = useApplicationDispatch();

  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const signInTracking = useEventTracking('login');

  const onSubmit = () => {
    signInTracking();
    authClient
      .signIn(form.data)
      .then(t => applicationDispatch(setToken(t)))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  useEffect(() => {
    if (user) {
      navigate(user.claims?.includes('Panel') ? "/" : "/");
    }
  }, [user]);

  return (
    <>
      <MetaHead title="Zaloguj się" description="Zaloguj się do panelu klienta i zarządzaj swoimi subsrypcjami, pobieraj faktury oraz dokonuj płatności." />
      <h2 className="text-lg font-semibold text-gray-900">
        Zaloguj się
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        Zaloguj się do panelu klienta i zarządzaj swoimi subsrypcjami, pobieraj faktury oraz dokonuj płatności.
      </p>
      <p className="mt-2 text-sm text-gray-700">
        Wpisz poniżej dane do swojego konta.
      </p>
      <form onSubmit={e => form.onSubmit(e, onSubmit)} className="mt-10 grid grid-cols-1 gap-y-8">
        <FormInput.Overlapping
          {...form.input('email', 'email', { placeholder: 'Adres e-mail', autoComplete: 'username', required: true })}
        />
        <FormInput.Overlapping
          {...form.input('password', 'password', { placeholder: 'Hasło', autoComplete: 'current-password', required: true })}
        />
        <div>
          <Button type="submit" variant="solid" color="primary" className="w-full">
            <span>
              Zaloguj się <span aria-hidden="true">&rarr;</span>
            </span>
          </Button>
        </div>
      </form>
    </>
  )
}
