import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faExclamationCircle, faInfoCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactNode, useState } from 'react';

export enum AlertType {
  Success,
  Warning,
  Error,
  Information
}

export interface IAlertComponentWithoutTypeProps {
  title: string;
  children?: ReactNode;
}

export interface IAlertComponentProps extends IAlertComponentWithoutTypeProps {
  type: AlertType;
}

export interface IAlertChildrenComponentPorps {
  children: ReactNode;
}

export interface IAlertListComponentProps {
  title: string;
  href?: string;
  onClick?: string;
}

interface IAlertColorPallete {
  bg: string;
  icon: string;
  title: string;
  closeButton: string;
  description: string;
}

const AlertColorPalletes: { [key: string]: IAlertColorPallete; } = {
  success: {
    bg: 'bg-emerald-50',
    icon: 'text-emerald-400',
    title: 'text-emerald-800',
    closeButton: 'bg-emerald-50 text-emerald-500 hover:bg-emerald-100 focus:ring-emerald-600 focus:ring-offset-emerald-50',
    description: 'text-emerald-700'
  },
  warning: {
    bg: 'bg-amber-50',
    icon: 'text-amber-400',
    title: 'text-amber-800',
    closeButton: 'bg-amber-50 text-amber-500 hover:bg-amber-100 focus:ring-amber-600 focus:ring-offset-amber-50',
    description: 'text-amber-700'
  },
  error: {
    bg: 'bg-rose-50',
    icon: 'text-rose-400',
    title: 'text-rose-800',
    closeButton: 'bg-rose-50 text-rose-500 hover:bg-rose-100 focus:ring-rose-600 focus:ring-offset-rose-50',
    description: 'text-rose-700'
  },
  information: {
    bg: 'bg-sky-50',
    icon: 'text-sky-400',
    title: 'text-sky-800',
    closeButton: 'bg-sky-50 text-sky-500 hover:bg-sky-100 focus:ring-sky-600 focus:ring-offset-sky-50',
    description: 'text-sky-700'
  }
}

const getPalleteNameByType = (type: AlertType): string => {
  switch (type) {
    case AlertType.Success:
      return 'success';
    case AlertType.Warning:
      return 'warning';
    case AlertType.Error:
      return 'error';
    case AlertType.Information:
      return 'information';
  }
}

const getColorPalleteByType = (type: AlertType): IAlertColorPallete => {
  return AlertColorPalletes[getPalleteNameByType(type)];
}


const Alert: FC<IAlertComponentProps> = (props: IAlertComponentProps) => {
  const { title, type, children } = props;
  const [isVisible, setVisible] = useState(true);
  const onClickClose = () => setVisible(false);

  const pallete = getColorPalleteByType(type);

  if (!isVisible) return <></>;

  return (
    <div className={`rounded-md ${pallete.bg} p-4 my-5`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {type === AlertType.Success && <FontAwesomeIcon icon={faCheckCircle} className={`h-5 w-5 ${pallete.icon}`} aria-hidden="true" />}
          {type === AlertType.Error && <FontAwesomeIcon icon={faTimesCircle} className={`h-5 w-5 ${pallete.icon}`} aria-hidden="true" />}
          {type === AlertType.Information && <FontAwesomeIcon icon={faInfoCircle} className={`h-5 w-5 ${pallete.icon}`} aria-hidden="true" />}
          {type === AlertType.Warning && <FontAwesomeIcon icon={faExclamationCircle} className={`h-5 w-5 ${pallete.icon}`} aria-hidden="true" />}
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${pallete.title}`}>{title}</h3>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${pallete.closeButton}`}
              onClick={onClickClose}
            >
              <span className="sr-only">Close</span>
              <FontAwesomeIcon icon={faTimes} className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      {children && typeof (children) === "string" && <p className={`mt-2 text-sm ${pallete.description}`}>{children}</p>}
      {children && typeof (children) !== "string" && <div className={`mt-2 text-sm ${pallete.description}`}>{children}</div>}
    </div>
  )
}

const AlertList: FC<IAlertChildrenComponentPorps> = (props: IAlertChildrenComponentPorps) =>
  <ul role="list" className="list-disc pt-3 space-y-1 pl-5">{props.children}</ul>

const AlertSuccess: FC<IAlertComponentWithoutTypeProps> = (props: IAlertComponentWithoutTypeProps) =>
  <Alert {...props} type={AlertType.Success} />
const AlertWarning: FC<IAlertComponentWithoutTypeProps> = (props: IAlertComponentWithoutTypeProps) =>
  <Alert {...props} type={AlertType.Warning} />
const AlertError: FC<IAlertComponentWithoutTypeProps> = (props: IAlertComponentWithoutTypeProps) =>
  <Alert {...props} type={AlertType.Error} />
const AlertInformation: FC<IAlertComponentWithoutTypeProps> = (props: IAlertComponentWithoutTypeProps) =>
  <Alert {...props} type={AlertType.Information} />

export default {
  Success: AlertSuccess,
  Warning: AlertWarning,
  Error: AlertError,
  Information: AlertInformation,
  List: AlertList,
  Default: Alert
};