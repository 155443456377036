import { Container } from 'src/components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons'

export function MobileBanner() {
  return (
    <section
      id="mobile"
      className="relative overflow-hidden bg-slate-900 py-24 xl:py-48"
    >
      <Container className="relative">
        <div className="relative z-20 mx-auto text-left">
          <div className="lg:max-w-2xl xl:max-w-3xl">
            <h2 className="font-display text-4xl tracking-tight text-white">
              Aplikacja mobilna
            </h2>
            <div className="my-8 text-slate-200 text-left grid grid-cols-1 gap-y-2 text-lg">
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Bądź zawsze na bieżąco z wydarzeniami w stajni!
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Nie przegap żadnego treningu dzięki powiadomieniom Push.
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Zarządzaj treningami w prosty sposób, w dowolnym miejscu.
              </div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> Dostęp do Twojego konta w każdej chwili zawsze pod ręką.
              </div>
            </div>
            <p className="mt-8 text-slate-300 text-xl">
              Skorzystaj z naszej aplikacji mobilnej, aby zawsze mieć dostęp do
              swojego konta. Zarządzaj swoimi finansami z dowolnego miejsca.
            </p>
          </div>
          <div className="text-center md:text-start text-xl lg:text-2xl xl:text-3xl">
            <p className="mt-8 tracking-tight text-primary-500">
              Już wkrótce dostępna w sklepie Google Play i App Store.
            </p>
            <p className="mt-2 tracking-tight text-secondary-500">
              Aplikacja mobilna zostanie udostępniona jeszcze w tym roku!
            </p>
          </div>
        </div>
      </Container>
      <div className="hidden lg:block absolute z-10 right-16 -bottom-48 w-1/4 max-w-96">
        <img src='/images/screenshots/simulator.webp' />
      </div>
    </section>
  )
}
