/* eslint-disable @typescript-eslint/no-explicit-any */
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Container } from 'src/components/Container'
import Data from 'src/data/Faq.json';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import ContentParse from './Cms/ContentParse';

export interface FaqData {
  id: string;
  translations: FaqDataTranslation[];
}

export interface FaqDataTranslation {
  culture: string;
  question: string;
  answer: string;
}

export function Faqs() {
  const data = Data as FaqData[];
  const faqTranslation = useEntityTranslation<FaqData, FaqDataTranslation>();
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-0 top-0 w-full h-full object-cover"
        src='/images/photos/faq.svg'
        alt="Background"
      />
      <Container className="relative">
        <h2
          id="faq-title"
          className="font-display text-4xl sm:text-5xl text-primary-700 tracking-tight mb-16"
        >
          Najczęściej zadawane pytania:
        </h2>
        <div>
          {data.map((faq, i) => (
            <div className="rounded-lg border border-gray-200 py-2 px-5 mb-3 bg-white" id={`${i}`}>
              <Disclosure>
                {({ open }: any) => (
                  <>
                    <div className="w-full">
                      <DisclosureButton className="py-2 font-medium w-full text-left text-lg flex justify-between">
                        <span>{faqTranslation.getCurrentTranslation(faq)?.question}</span>
                        <FontAwesomeIcon icon={open ? faChevronCircleUp : faChevronCircleDown} className="ml-3 h-6 text-secondary-500" />
                      </DisclosureButton>
                      <DisclosurePanel className="text-gray-700">
                        <p className="p-4"><ContentParse>{faqTranslation.getCurrentTranslation(faq)?.answer}</ContentParse></p>
                      </DisclosurePanel>
                    </div>
                  </>
                )}
              </Disclosure>
            </div>
          ))}
        </div>
        <p className="mt-8 text-lg tracking-tight text-slate-700 text-center">
          Jeżeli nie możesz znaleźć tutaj pytania, na które szukasz odpowiedzi chętnie na nie odpowiemy!<br />
          Skontaktuj się z nami za pomocą e-maila lub zadzwoń do nas!
        </p>
      </Container>
    </section>
  )
}
